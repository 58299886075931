import React from "react";

import {
  useCartCount,
  useCartTotals,
  useCheckout,
  useCheckoutStatus,
} from "state/shopify";

import Box from "components/Box";
import Button from "components/Button";
import Heading from "components/Heading";
import Text from "components/Text";

const style = {
  wrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    textAlign: "center",
    "& > *": {
      mb: 3,
    },
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
  },
};

const CartSummary = ({ title, sx, ...props }) => {
  const cartCount = useCartCount();
  const { subtotal } = useCartTotals();
  const handleCheckout = useCheckout();
  const { isInitialized } = useCheckoutStatus();

  if (isInitialized && cartCount < 1) {
    return (
      <Box sx={{ ...style.wrapper, ...sx }} {...props}>
        <Text variant="display2">Your cart is empty.</Text>
        <Button variant="secondary" to="/store">
          Continue shopping →
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ ...style.wrapper, ...sx }} {...props}>
      {title && <Heading>{title}</Heading>}
      <Box sx={style.total}>
        <span>
          {`Subtotal (${cartCount} item${cartCount === 1 ? "" : "s"}):`}
        </span>
        <span>{subtotal || "---"}</span>
      </Box>
      <Text as="small" sx={{ mb: 4 }}>
        <em>Shipping cost and tax will be finalized at checkout.</em>
      </Text>
      <Button onClick={handleCheckout} disabled={cartCount < 1}>
        Checkout
      </Button>
      <Button variant="secondary" to="/store">
        Keep shopping →
      </Button>
    </Box>
  );
};

export default CartSummary;
