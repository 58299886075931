import React from "react";

import Box from "components/Box";
import Heading from "components/Heading";

import LineItems from "./LineItems";
import Summary from "./Summary";

const style = {
  wrapper: {
    "--p": t =>
      `clamp(0px, calc((100% - ${t.sizes.xl}px) / 2), ${
        (t.sizes.xxl - t.sizes.xl) / 2
      }px)`,
    position: "relative",
    display: "grid",
    gridTemplateColumns: [
      "minmax(0px, 1fr)",
      null,
      t =>
        `minmax(0px, calc(${
          (t.sizes.xl * 2) / 3
        }px + var(--p))) minmax(0px, calc(${
          (t.sizes.xl * 1) / 3
        }px + var(--p)))`,
    ],
    width: "100%",
    maxWidth: "xxl",
    mx: "auto",
    bg: "white",
    borderRadius: "lg",
  },
  column: {
    boxSizing: "content-box",
    py: 5,
    "&:last-of-type": {
      color: "turquoise.900",
      bg: "turquoise.50",
      borderTopRightRadius: "lg",
      borderBottomRightRadius: "lg",
    },
  },
  lineItems: {
    maxWidth: "lg",
    ml: [0, 0, "auto"],
    px: [3, 3, 4.5],
  },
  heading: {
    maxWidth: "lg",
    ml: [0, 0, "auto"],
    px: [3, 3, 4.5],
  },
  summary: {
    position: "sticky",
    maxWidth: "sm",
    top: 4,
    px: [3, 3, 4.5],
  },
};

const Cart = () => {
  return (
    <Box sx={style.wrapper}>
      {/* <Box sx={style.container}> */}
      <Box sx={style.column}>
        <Heading sx={style.heading}>Your Cart</Heading>
        <LineItems sx={style.lineItems} />
      </Box>
      <Box sx={style.column}>
        <Summary sx={style.summary} />
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default Cart;
