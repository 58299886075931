import React from "react";

import { getThemeProps } from "style";
import useGetProductURL from "hooks/useGetProductURL";
import { isDefaultOption } from "utils/shopify";
import {
  useCartItems,
  useCheckoutStatus,
  useGetVariantImage,
  useRemoveItemFromCart,
  useUpdateItemsInCart,
} from "state/shopify";

import Box from "components/Box";
import Button from "components/Button";
import Counter from "components/Counter";
import Image from "components/Image";
import Link from "components/Link";
import Price from "components/Price";
import Text from "components/Text";
import VisuallyHidden from "components/VisuallyHidden";

const style = {
  wrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    justifyContent: "center",
    height: "100%",
    // p: 4,
  },
  header: {
    display: ["none", "grid"],
    gridTemplateColumns: "100px minmax(0px, 1fr) 6em 6em",
    gridTemplateAreas: `"image description quantity price"`,
    gridRowGap: 1,
    gridColumnGap: 4,
    mb: 3,
  },
  list: {
    m: 0,
    p: 0,
  },
  item: {
    display: "grid",
    gridTemplateColumns: [
      "repeat(3, minmax(0px, 1fr))",
      "100px minmax(0px, 1fr) 6em 6em",
    ],
    gridTemplateRows: "fit-content(2rem) minmax(0px, 1fr)",
    gridTemplateAreas: [
      `"image description description" "quantity controls price"`,
      `"image description quantity price" "image description quantity controls"`,
    ],
    gridRowGap: [4, 1],
    gridColumnGap: [3, 4],
    alignItems: "start",
    justifyItems: "start",
    mx: 0,
    py: 3,
    borderTop: "1px solid",
    borderColor: "gray.200",
    fontSize: 1,
  },
  image: {
    position: "relative",
    width: [75, 100],
    height: [75, 100],
    backgroundColor: "gray.200",
    gridArea: "image",
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
  },
  description: {
    gridArea: "description",
  },
  title: {
    lineHeight: 1,
    fontWeight: 400,
  },
  quantity: {
    gridArea: "quantity",
    justifySelf: [null, "center"],
  },
  price: {
    gridArea: "price",
    justifySelf: [null, "end"],
  },
  controls: {
    gridArea: "controls",
    fontSize: 1,
    justifySelf: [null, "end"],
  },
  variantOptions: {
    fontSize: 1,
    color: "gray.500",
    "dt, dd": {
      display: "inline",
    },
    "dt::after": {
      content: "': '",
      display: "inherit",
    },
    dd: {
      m: 0,
    },
  },
};

const LineItem = ({ id, quantity, styleVariant, title, variant }) => {
  const getURL = useGetProductURL();
  const getVariantImage = useGetVariantImage();
  const [image, setImage] = React.useState({});
  const updateItemsInCart = useUpdateItemsInCart();
  const removeItemFromCart = useRemoveItemFromCart();

  React.useEffect(() => {
    if (!image.src) {
      getVariantImage(variant).then(setImage).catch(console.warn);
    }
  }, [getVariantImage, image, variant]);

  const handleRemove = React.useCallback(() => {
    removeItemFromCart(id);
  }, [removeItemFromCart, id]);

  const handleQuantityChange = React.useCallback(
    quantity => {
      updateItemsInCart([{ id, quantity }]);
    },
    [updateItemsInCart, id]
  );

  const themeProps = getThemeProps({
    style,
    themeKey: "cart.lineItems",
    variant: styleVariant,
  });

  function renderVariantOption(option) {
    if (isDefaultOption(option)) {
      return null;
    }

    return (
      <Box as="div" key={option.name} {...themeProps("variantOption")}>
        <dt>{option.name}</dt>
        <dd>{option.value}</dd>
      </Box>
    );
  }

  const url = getURL(variant.product.handle);

  return (
    <Box {...themeProps("item")}>
      {/* image */}
      <Box {...themeProps("image")}>
        {image && <Image {...image} />}
        <Link to={url} aria-hidden tabIndex="-1" variant="cover">
          <VisuallyHidden>{title}</VisuallyHidden>
        </Link>
      </Box>

      {/* description */}
      <Text {...themeProps("description")}>
        <Link to={url} variant="plain">
          <Box as="span" {...themeProps("title")}>
            {title}
          </Box>
        </Link>
        {variant.selectedOptions && (
          <Box as="dl" {...themeProps("variantOptions")}>
            {variant.selectedOptions.map(option => {
              return renderVariantOption(option);
            })}
          </Box>
        )}
      </Text>

      {/* quantity */}
      <Counter
        {...themeProps("quantity")}
        type="counter"
        variant="plain"
        aria-label="quantity"
        value={quantity}
        onChange={handleQuantityChange}
      />

      {/* controls */}
      <Button
        {...themeProps("controls")}
        variant="buttons.plain"
        onClick={handleRemove}
      >
        Remove
      </Button>

      {/* price */}
      <Price
        {...themeProps("price")}
        price={variant.priceV2}
        compareAtPrice={variant.compareAtPriceV2}
      />
    </Box>
  );
};

LineItem.defaultProps = {
  styleVariant: "default",
};

const CartLineItems = ({ sx, ...props }) => {
  const { isBusy, isInitialized } = useCheckoutStatus();
  const items = useCartItems();

  if (!isInitialized) {
    return (
      <Box
        sx={{ ...style.wrapper, ...sx }}
        __themeKey="cart.lineItems"
        variant="loading"
      >
        {"Loading…"}
      </Box>
    );
  }

  if (items.length < 1) {
    return (
      <Box
        sx={{ ...style.wrapper, ...sx }}
        __themeKey="cart.lineItems"
        variant="empty"
      >
        {"Your cart is empty!"}
      </Box>
    );
  }

  return (
    <Box
      sx={{ ...style.wrapper, ...sx }}
      style={{ opacity: isBusy ? 0.5 : 1 }}
      __themeKey="cart.lineItems"
      variant="default"
    >
      <Box sx={style.header}>
        <Text sx={style.quantity}>Quantity</Text>
        <Text sx={style.price}>Price</Text>
      </Box>
      <Box as="ul" sx={style.list}>
        {items.map(item => (
          <LineItem key={item.id} {...item} />
        ))}
      </Box>
    </Box>
  );
};

export default CartLineItems;
